import React, { Component } from 'react'
import { graphql } from 'gatsby';
//styles
import './styles.scss'
import SEO from '../seo';
import Layout from '../layout';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      updatedAt(fromNow: true)
      blogText {
       json
      }
    }
  } 
`;

class BlogPost extends Component {

  render() {

    const data = this.props.data.contentfulBlogPost;

    const options = {
        renderNode: {
            'embedded-asset-block': (node) => {

                if (node.data.target.fields.file) {
                    const alt = node.data.target.fields.title['en-US'];
                    const url = node.data.target.fields.file['en-US'].url;

                    return <img src={url} alt={alt} />
                }

                return null;
            }
        }
    };

    return (
        <Layout>
          <SEO title={data.title} />

            <div className="main-body-inner">
                <div className="wrapper">
                    <div>
                        <h2 className="ct-title dark">{data.title}</h2>
                        <span>{data.updatedAt}</span>
                        {documentToReactComponents(data.blogText.json, options)}
                    </div>
                </div>
            </div>
        </Layout>
    )
  }
}

export default BlogPost
